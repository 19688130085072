/* src/components/Banner/Banner.css */

.banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    background: linear-gradient(135deg, #6e8efb, #a777e3);
    color: white;
    text-align: center;
    height: 65vh;
  }
  
  .profile-picture {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 1rem;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  }
  
  .profile-picture img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .banner-text h1 {
    margin: 0;
    font-size: 2.5rem;
  }
  
  .banner-text p {
    margin: 0.5rem 0 0;
    font-size: 1.2rem;
  }