/* src/components/Showcase/Showcase.css */

.showcase {
    padding: 4rem 2rem;
    background-color: #f9f9f9;
    text-align: center;
  }
  
  .showcase h2 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }
  
  .showcase-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
  }
  
  .showcase-item {
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .showcase-item:hover {
    transform: scale(1.05);
  }
  
  .showcase-iframe {
    width: 100%;
    height: 200px; /* Adjust height as needed */
    overflow: hidden;
  }
  
  .showcase-iframe iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
  
  .showcase-content {
    padding: 1rem;
  }
  
  .showcase-content h3 {
    font-size: 1.5rem;
    margin: 0 0 0.5rem;
  }
  
  .showcase-content p {
    font-size: 1rem;
    color: #666;
  }