/* src/components/ContactUs/ContactUs.css */

.contact-us {
    padding: 4rem 2rem;
    background-color: #f9f9f9;
    text-align: center;
  }
  
  .contact-us h2 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
  }
  
  .success-message {
    color: green;
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
  
  form {
    max-width: 600px;
    margin: 0 auto;
    text-align: left;
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  
  input[type="text"],
  input[type="email"],
  textarea {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  textarea {
    height: 150px;
  }
  
  input.error,
  textarea.error {
    border-color: red;
  }
  
  .error-message {
    color: red;
    font-size: 0.875rem;
  }
  
  button {
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 4px;
    background-color: #6e8efb;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #5a75c9;
  }
  
  .honeypot {
    display: none;
  }