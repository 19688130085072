/* src/components/ScrollToTop/ScrollToTop.css */

.scroll-to-top {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    z-index: 1000;
    cursor: pointer;
  }
  
  .scroll-to-top div {
    background-color: #333;
    color: white;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s;
  }
  
  .scroll-to-top div:hover {
    background-color: #555;
  }