/* src/components/Navbar/Navbar.css */

/* Basic styles */
body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1000;
}

.navbar-brand {
  font-size: 1.5rem;
  font-weight: bold;
}

.navbar-toggler {
  display: none;
  font-size: 1.5rem;
  background: none;
  border: none;
  cursor: pointer;
  color: black;
}

.navbar-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex; /* Ensure flex display by default */
  flex-direction: column; /* Column direction for smaller screens */
  align-items: center;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.navbar-item {
  width: 100%;
  text-align: center;
}

.navbar-item a {
  display: block;
  width: 100%;
  padding: 1rem;
  text-decoration: none;
  color: #333;
  font-size: 1rem;
}

/* Responsive styles */
@media (max-width: 768px) {
  .navbar-toggler {
    display: block;
  }

  .navbar-menu {
    flex-direction: column;
  }

  .navbar-item a {
    padding: 0.5rem 1rem;
  }
}

@media (min-width: 769px) {
  .navbar-menu {
    position: static;
    flex-direction: row; /* Row direction for larger screens */
    background-color: transparent;
    box-shadow: none;
    height: auto !important;
    opacity: 1 !important;
    display: flex !important;
    justify-content: flex-end; /* Align items to the end of the navbar */
  }

  .navbar-item {
    width: auto; /* Ensure auto width for items */
    margin-left: 2rem;
    text-align: left;
  }

  .navbar-item:first-child {
    margin-left: 0; /* Remove margin from the first item */
  }

  .navbar-item a {
    padding: 0;
    color: #333;
    font-size: 1rem;
  }
}